.custom-shape-divider-top-1733937969 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1733937969 svg {
  position: relative;
  display: block;
  width: calc(100%);
  height: 190px;
}

@media (max-width: 899px) {
  .custom-shape-divider-top-1733937969 svg {
    height: 150px;
  }
}

@media (max-width: 600px) {
  .custom-shape-divider-top-1733937969 svg {
    height: 110px;
  }
}

.custom-shape-divider-top-1733937969 .shape-fill {
  fill: #07269b;
}
